import axios from "axios";
// ----------------------------------------------------------------------

export const axiosInstance = axios.create();

if (process.env.REACT_APP_BACKEND_MODE === "PROD") {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_PROD;
} else if (process.env.REACT_APP_BACKEND_MODE === "UAT") {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_UAT;
} else {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_LOCAL;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export const endpoint = {
  token: {
    root: "/t",
  },
  kelulusan: {
    root: "/siswa-kelulusan",
    option: "/unit-cabang",
  },
};