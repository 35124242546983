import * as React from 'react';

// Pemanggilan component dari library
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

// Pemanggilan assets image dari directory local
import logo from '../assets/img/ypi-logo.png';


// Pemanggilan component dari directory local
import Footer from "../component/Footer";

// Pemanggilan fungsi route dari library
import { useLocation } from 'react-router-dom';



// Fungsi component thema

const defaultTheme = createTheme();


const DetailKelulusan = () => {


    // Fungsi menampung data hasil submit

    const location = useLocation();

    const data = location.state ? location.state : null;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         <Box
        component="img"
        sx={{
            height: 300,
            width: 350,
            maxHeight: { xs: 300, md: 250 },
            maxWidth: { xs: 350, md: 250 },
        }}
        alt="YSB"
        src={logo}
        />
          <Typography component="h3" variant="b">
           Pengumuman Kelulusan Tahun {new Date().getFullYear()}
          </Typography>
          
          <Box sx={{ mt: 1 }}>

          <Stack spacing={3}>

          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="header-table">
            <TableBody>
            <TableRow>
                <TableCell style={{ width: '300px' }}><Typography variant="p" fontWeight="bold">NISN</Typography></TableCell>
                <TableCell style={{ width: '800px' }} align="left">{ data[0].nisn ? data[0].nisn : ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: '300px' }}><Typography variant="p" fontWeight="bold">NIS</Typography></TableCell>
                <TableCell style={{ width: '800px' }} align="left">{ data[0].nis ? data[0].nis : ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: '300px' }}><Typography variant="p" fontWeight="bold">Nama Siswa</Typography></TableCell>
                <TableCell style={{ width: '800px' }} align="left">{ data[0].nama_siswa ? data[0].nama_siswa : ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: '300px' }}><Typography variant="p" fontWeight="bold">Cabang</Typography></TableCell>
                <TableCell style={{ width: '800px' }} align="left">{ data[0].nama_sekolah ? data[0].nama_sekolah : ""}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        

      {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="header-table">
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="p" fontWeight="bold">Nilai Rata - Rata</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell >{ data[0].nilai_rata ? data[0].nilai_rata : ""}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer> */}

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="header-table">
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="h6" fontWeight="bold">STATUS KELULUSAN DINYATAKAN</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell > { data[0].status ? data[0].status == 'Lulus' ? (<Alert variant="filled" severity="success" sx={{ width: '360px','& .MuiAlert-message':{textAlign:"center", width:"inherit"} }}>
            <Typography variant="h4">{ data[0].status.toUpperCase()}</Typography>
          </Alert>) : (<Alert variant="filled" severity="error" sx={{ width: '360px','& .MuiAlert-message':{textAlign:"center", width:"inherit"} }}>
          <Typography variant="h4">{ data[0].status.toUpperCase()}</Typography>
    </Alert>) : ''}</TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
        <TableRow>
            <TableCell > { data[0].status ? data[0].status == 'Lulus' ? (<Typography variant="p">
            Selamat Melanjutkan Ke Jenjang Berikutnya Perguruan Tinggi, Sukses Untuk <Typography variant="p" fontWeight="bold">{data[0].nama_siswa}</Typography>
            </Typography>) : (<Typography variant="p" >
          Mohon Maaf Atas Informasi Yang Disampaikan.
          </Typography>) : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>


    { data[0].status == 'Lulus' ?
    
    (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="header-table">
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="h8" fontWeight="bold">INFORMASI</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
            <TableCell ><Typography variant="p">{data[0].keterangan ? data[0].keterangan : ""}</Typography></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>)
    : ""
    }

     


      </Stack>


            
        </Box>
        </Box>
      </Container>
      <Footer sx={{ mt: 8, mb: 4 }}/>
    </ThemeProvider>
  );
}

export default DetailKelulusan