import * as React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";

const LoadingButton = ({ children, loading, ...rest }) => {
    return (
      <Button {...rest}>
        {children}
        {loading && (
          <CircularProgress
            size={30}
            color="secondary"
            style={{ position: "absolute" }}
          />
        )}
      </Button>
    );
  };
  
  export default LoadingButton;