import * as React from 'react';

// Pemanggilan component dari library
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Pemanggilan assets image dari directory local
import logo from '../assets/img/ypi-logo.png';

// Pemanggilan endpoint API dari directory local
import { axiosInstance as axios, endpoint } from '../api/axios';

// Pemanggilan hook dari library react
import { createContext, useContext, useState, useEffect, useRef  } from 'react';

// Pemanggilan fungsi validasi dari library
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";

// Pemanggilan component dari directory local
import LoadingButton from "../component/LoadingButton";
import Footer from "../component/Footer";

// Pemanggilan fungsi route dari library
import { useNavigate } from 'react-router-dom';

// Pemanggilan fungsi alert dari library
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

// Pemanggilan hook global state library redux
import {useSelector, useDispatch} from "react-redux";

// Pemanggilan fungsi event dari directory local redux
import { submitData } from "../redux/slice/submit";

// Pemanggilan library tanggal dan waktu
import { format } from 'date-fns-tz';


// Fungsi component thema

const defaultTheme = createTheme();

export default function Kelulusan() {

  // Fungsi hook redux

  const dispatch = useDispatch();

  // Fungsi component alert

  const { enqueueSnackbar } = useSnackbar();

  // Fungsi route

  const navigate = useNavigate();


  // Hook menampung tanggal dan waktu saat ini


  const [currentDateTime, setCurrentDateTime] = useState("");

   // Hook menampung data unit
  
  const [unit, setUnit] = useState([]);

  // Hook untuk menampung data pencarian kelulusan

  const [state, setState] = useState({
    nisn:'',
    id_yskl_unit:''
  });

  // Hook untuk menampung data dan mengelola waktu proses loading button

  const [loading, setLoading] = useState(false);
  const timer = useRef();

  // Hook untuk merender data dan mengelola waktu proses loading button

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // Hook untuk merender data unit
  
  useEffect(() => {
    getUnit();
  }, []);


  // Hook untuk merender data tanggal dan waktu saat ini


  useEffect(() => {
    // Mendapatkan waktu saat ini dalam zona waktu Indonesia
    const now = new Date();
    const tz = 'Asia/Jakarta';
    const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss', { timeZone: tz });
    
    // Menyimpan waktu saat ini dalam state
    setCurrentDateTime(formattedDateTime);

    // Mengupdate waktu setiap 1 detik
    const interval = setInterval(() => {
      const updatedDateTime = format(new Date(), 'yyyy-MM-dd HH:mm:ss', { timeZone: tz });
      setCurrentDateTime(updatedDateTime);
    }, 1000);

    // Membersihkan interval saat komponen dibongkar
    return () => clearInterval(interval);
  }, []);


  // Fungsi memanggil dan mengirim parameter data unit dari endpoint API
  
  const getUnit = async (id) => {

    const params = {
      id: id,
    };

    // const response = await axios.get(endpoint.kelulusan.option, {
    //   params: params
    // });
    // if (response && response.data) {
    //   setUnit(response.data);
    // }

    let postDataResponse, getDataResponse;

    [postDataResponse, getDataResponse] = await Promise.all([
      axios.post(endpoint.token.root),
      axios.get(endpoint.kelulusan.option, {
          params: params, headers: {
            Authorization: `Bearer ${postDataResponse}`
          }
        })
    ]);

    if (getDataResponse && getDataResponse.data) {
      setUnit(getDataResponse.data);
    }

  };

   // Fungsi validation input

  const ValidationForm = Yup.object().shape({
    nisn: Yup.string()
      .required("NISN Tidak Boleh Kosong"),
      id_yskl_unit: Yup.string()
      .required("Unit Cabang Al Azhar Summarecon Tidak Boleh Kosong"),
  });


   // Fungsi mengahandle data dari inputan 

  const handleChange = (field, event) => {
    let newState = { ...state };
    let value = null;

    value = event.target.value;

    newState[field] = value;
 
    setState(newState);
  };

  // Fungsi mengahandle submit

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: state,
    validationSchema: ValidationForm,
    onSubmit: async (values) => {
      try {


        // Fungsi memanggil endpoint API 

        // let getResponse = await axios.get(endpoint.kelulusan.root, {params : values});

        let postResponse, getResponse;

        [postResponse, getResponse] = await Promise.all([
          axios.post(endpoint.token.root),
          axios.get(endpoint.kelulusan.root, {
            params : values, headers: {
                Authorization: `Bearer ${postResponse}`
              }
            })
        ]);

        let tanggalPengumuman = new Date(getResponse.data[0].tanggal_pengumuman);

        let tanggalSaatIni = new Date(currentDateTime);

        if(getResponse.data.length > 0 && tanggalSaatIni < tanggalPengumuman){

          
          // Fungsi menghandle proses loading button dan menampilkan alert pencarian data 

          if (!loading) {
            setLoading(true);
            timer.current = window.setTimeout(() => {
              setLoading(false);
              enqueueSnackbar('Mohon maaf belum waktu nya pengumuman.', {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              });
            }, 3000);
          }
         

        }else if(getResponse.data.length > 0 && tanggalSaatIni > tanggalPengumuman){


           // Fungsi menghandle proses loading button dan redirect halaman pencarian data 

           if (!loading) {
            setLoading(true);
            timer.current = window.setTimeout(() => {
              setLoading(false);
              navigate('/detail-kelulusan', { state: getResponse.data});
            }, 3000);
          }

          // Fungsi hook redux
          dispatch(submitData());
          
        }
        
       
      } catch (error) {
        // Fungsi menghandle proses loading button dan menampilkan alert pencarian data 

        if (!loading) {
          setLoading(true);
          timer.current = window.setTimeout(() => {
            setLoading(false);
            enqueueSnackbar('Data tidak ditemukan.', {
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          }, 3000);
        }
      }
    },
  });

  // Fungsi menampung variable submit

  const { handleSubmit } = formik;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         <Box
        component="img"
        sx={{
            height: 300,
            width: 350,
            maxHeight: { xs: 300, md: 250 },
            maxWidth: { xs: 350, md: 250 },
        }}
        alt="YSB"
        src={logo}
        />
          <Typography component="h3" variant="b">
           Pengumuman Kelulusan Tahun {new Date().getFullYear()}
          </Typography>
          <Typography component="p" variant="p">
           Masukan NISN Pada Form Tersedia
          </Typography>
          <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label="NISN"
              value={state.nisn}
              onChange={(e) => handleChange("nisn", e)}
              autoComplete="off"
              type="number"
              error={Boolean(formik.touched.nisn && formik.errors.nisn)}
              helperText={formik.touched.nisn && formik.errors.nisn}
              autoFocus
            />

            <FormControl fullWidth>
              <InputLabel id="id_yskl_unit">Pilih Cabang Al Azhar Summarecon</InputLabel>
              <Select
                labelId="id_yskl_unit"
                id="id_yskl_unit"
                value={state.id_yskl_unit}
                label="Pilih Cabang Al Azhar Summarecon"
                onChange={(e) => handleChange("id_yskl_unit", e)}
                error={Boolean(formik.touched.id_yskl_unit && formik.errors.id_yskl_unit)}
                helperText={formik.touched.id_yskl_unit && formik.errors.id_yskl_unit}
              >
                {unit.map((cabang) => (
                  <MenuItem key={cabang.id} value={cabang.id}>
                    {cabang.nama_sekolah}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Periksa
            </LoadingButton>
          </Box>
          </Form>
          </FormikProvider>
        </Box>
      </Container>
      <Footer sx={{ mt: 8, mb: 4 }}/>
    </ThemeProvider>
  );
}