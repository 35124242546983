// Pemanggilan component dari library
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';

const defaultTheme = createTheme();

const Footer = () => {
    return (
      <ThemeProvider theme={defaultTheme}>
         <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[300]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
        <Typography variant="body1" color="text.secondary" align="center">
        {'©'}
        {new Date().getFullYear()}
        {'-'}
        YAYASAN SYIAR BANGSA
      </Typography>
      </Container>
      </Box>
      </Box>
      </ThemeProvider>
    );
  };
  
export default Footer;