// Pemanggilan fungsi route dari library
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Pemanggilan page / views
import Kelulusan from '../views/Kelulusan';
import DetailKelulusan from '../views/DetailKelulusan';

// Pemanggilan hook global state library redux
import {useSelector, useDispatch} from "react-redux";


// Pemanggilan fungsi alert dari library
import { SnackbarProvider, useSnackbar } from 'notistack';

// Fungsi mengembalikan ke route default jika tidak ada route yang terdaftar
function NotFound() {
  return <Navigate to="/" replace />;
}

const Path = () => {

  // Fungsi tampungan data global state 
  const submiteed = useSelector(state => state.isSubmitted);

  return (


    // Fungsi alert membungkus fungsi route dari library
    <SnackbarProvider>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Kelulusan/>} />
      <Route path="/detail-kelulusan" element={ submiteed ? <DetailKelulusan/> : <Navigate to="/" /> } />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </BrowserRouter>
    </SnackbarProvider>
   
  );
}

export default Path