// Action
export const submitData = () => ({
    type: 'SUBMIT_DATA'
});


// Reducer
const initialState = {
    isSubmitted: false
};


const submit = (state = initialState, action) => {
    switch (action.type) {
      case 'SUBMIT_DATA':
        return {
          ...state,
          isSubmitted: true
        };
      default:
        return state;
    }
  };
  
export default submit;