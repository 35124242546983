// Pemanggilan page / views
import Kelulusan from './views/Kelulusan';

// Pemanggilan route
import Path from './route/path';

// Pemanggilan component global state library redux
import { Provider} from "react-redux";

// Pemanggilan fungsi store redux directory local
import store from "./redux/store"


// import logo from './logo.svg';
// import './App.css';

function App() {

  

  return (

    <Provider store={store}>
    <Path>
    <Kelulusan/>
    </Path>
    </Provider>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    
  

  );
}

export default App;
